export function ETHIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_618_6395)">
                <path d="M20 40C31.0834 40 40 31.0834 40 20C40 8.9166 31.0834 0 20 0C8.9166 0 0 8.9166 0 20C0 31.0834 8.9166 40 20 40Z" fill="#02050E"/>
                <path d="M19.9975 5L10.7891 20.2781L19.9975 16.0935V5Z" fill="#8A92B2"/>
                <path d="M19.9975 16.0938L10.7891 20.2783L19.9975 25.7219V16.0938Z" fill="#62688F"/>
                <path d="M29.2085 20.2781L20 5V16.0935L29.2085 20.2781Z" fill="#62688F"/>
                <path d="M20 25.7219L29.2085 20.2783L20 16.0938V25.7219Z" fill="#454A75"/>
                <path d="M10.7891 22.0254L19.9975 35.0005V27.4655L10.7891 22.0254Z" fill="#8A92B2"/>
                <path d="M20 27.4655V35.0005L29.2136 22.0254L20 27.4655Z" fill="#62688F"/>
            </g>
            <defs>
                <clipPath id="clip0_618_6395">
                    <rect width="40" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
