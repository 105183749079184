import {LogoLite} from "../../icons/base/LogoLite";
import {navigation} from "./mappings";
export function Footer() {
    return (
        <footer className="bg-gray-900" aria-labelledby="footer-heading">
            <div className="wrapper p-0 ">
                <div className='flex flex-col items-center pb-20 w-full flex-wrap max-sm:px-5'>
                    <div className="flex flex-row max-sm:flex-col flex-wrap w-full px-6 justify-between items-center max-w-5xl max-sm:gap-16">
                        <div className="space-y-8">
                            <LogoLite/>
                            <div className="flex space-x-6">
                                {navigation.social.map((item) => (
                                    <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                                        <span className="sr-only">{item.name}</span>
                                        <item.icon className="h-6 w-6" aria-hidden="true"/>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-col justify-start items-start relative'>
                            <ul className="space-y-4 text-left max-sm:text-center">
                                {navigation.solutions.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href}
                                           className="text-sm leading-6 text-gray-300 hover:text-white">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex flex-col justify-start items-start relative">
                            <ul className="space-y-4 text-left">
                                {navigation.support.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href}
                                           className="text-sm leading-6 text-gray-300 hover:text-white">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="flex mt-16">
                        <p className="text-xs leading-5 font-poppins font-normal text-lightGray">&copy; 2024 Jigsaw, Inc.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
