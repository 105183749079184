// Custom components
import React from "react";

const PrimaryButton = ({label,className}) => (
    <div>
        <button className={`${className} bg-gradient-to-r from-blue to-lightBlue max-sm:w-full`}
            type="submit" name="submit-btn" value="SubmitValue"> {label}
        </button>
    </div>
);

export default PrimaryButton;
