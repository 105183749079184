import React from "react";
import {DaiIcon} from "../../icons/assets/DAI";

const Chip = ({icon=<DaiIcon />, title='DAI'}) => (
    <div className="flex flex-col justify-start items-start gap-2.5 z-20">
        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 w-40 h-18 gap-3 px-5 py-4 rounded-xl bg-[#7c4161]/20 backdrop-blur-[94px]">
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-3">
                {icon}
                <p>{title}</p>
            </div>
        </div>
    </div>
);

export default Chip;
