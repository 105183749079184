import { useEffect, useRef } from 'react';
import PrimaryButton from "../components/base/Button";
import video from '../media/video/JigSawHeroFootage.mp4'

export function Hero() {
    const heroRef = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        const onScroll = () => {
            if (heroRef.current) {
                heroRef.current.style.transform = `translateY(-${window.scrollY * 0.5}px)`;
            }
        };

        window.addEventListener('scroll', onScroll);

        // Try to play the video after the component mounts
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                // Auto-play was prevented
                // Show a UI element to let the user manually start playback
            });
        }

        // Cleanup on unmount
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <div ref={heroRef} className="sticky top-0 flex flex-col justify-center text-center h-screen">
            <video ref={videoRef} src={video} autoPlay muted loop playsInline id="video" preload="none" className='absolute -z-10 w-full h-full object-cover'/>
            <div className='mx-auto flex flex-col max-w-xl items-center gap-4 max-md:mx-6'>
                <h1>Bringing fluidity to your collateral</h1>
                <p className="mx-auto max-w-lg text-center drop-shadow-md">
                    Keep full control of your funds while enjoying unparalleled flexibility and seamless interoperability.
                </p>
                <div className="mt-10 flex justify-center gap-x-4">
                    <a href='https://app.jigsaw.finance/' target="_blank" rel='noreferrer'>
                        <PrimaryButton className='px-10' label="Get Started"> </PrimaryButton>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Hero
