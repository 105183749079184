export function RSWETHIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#FBC53A"/>
            <circle cx="20" cy="20" r="18" fill="#1E1272"/>
            <path d="M20.0857 7L20.0856 19.2727H13L20.0857 7Z" fill="#FFE8A8"/>
            <path d="M20.0857 7L20.0857 17.9091H27.3584L20.0857 7Z" fill="#FFBC01"/>
            <path d="M20.2727 32L20.2728 19.7273H27.3584L20.2727 32Z" fill="#FFBC01"/>
            <path d="M20.2727 32L20.2727 21.0909H13L20.2727 32Z" fill="#FFE8A8"/>
        </svg>


    )
}
