import Chip from "../components/base/Chip/Chip";
import {assetList} from "../components/base/Chip/mappings";
export function Assets() {
    return (
        <section id="assets" aria-label="Supported Assets">
            <div className='wrapper'>
                <div className='w-2/5 h-2/5 rounded-full blur-[330px] bg-primary absolute bottom-72 z-10 max-sm:bottom-80'/>
                <h2 className='z-20'> Supported Assets</h2>
                <div className="flex flex-row justify-center items-center flex-wrap relative gap-5 max-w-4xl">
                    {assetList.map((item) => (
                        <Chip key={item.title} title={item.title} icon={item.icon}/>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Assets
