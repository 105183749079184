import {useState} from 'react';
import {Dialog} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {LogoLite} from "../../icons/base/LogoLite";
import Button from "../../base/Button";
import {navigation} from './mappings';

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    // Function to handle smooth scroll to section
    const handleScrollToSection = (target) => {
        const element = document.getElementById(target);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        // Close the mobile menu after clicking a navigation item
        setMobileMenuOpen(false);
    };

    return (
        <header className="py-5 px-5 absolute w-full bg-transparent z-50">
            <nav className="mx-auto flex items-center justify-between" aria-label="Global">
                <a href="https://jigsaw.finance" aria-label='redirection to home page' className="-m-1.5 p-1.5"><LogoLite/></a>
                <div className="flex lg:hidden">
                    <button type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:bg-transparent"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <span className="sr-only">Toggle mobile menu</span>
                        {!mobileMenuOpen && (
                            <Bars3Icon style={{color: 'white'}} className="h-6 w-6 hover:bg-transparent"
                                       aria-hidden="true"/>
                        )}
                    </button>
                </div>
                <div className="hidden items-center lg:flex lg:gap-x-12">
                    {navigation.map(({name,target,link}) => (
                        <a href={link} key={name} onClick={() => handleScrollToSection(target)}>{name}</a>
                    ))}
                    <a href='https://app.jigsaw.finance' target="_blank" rel="noreferrer">
                        <Button label="Connect Wallet"/>
                    </a>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <div className="fixed inset-0 z-10"/>
                <Dialog.Panel
                    className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex flex-row justify-between items-center pt-2">
            <span>
            </span>
                        <button
                            type="button"
                            className="-m-3 rounded-md p-2.5 text-gray-700 hover:bg-transparent"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon style={{color: 'white'}} className="h-6 w-6 hover:bg-transparent"
                                       aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="mt-16 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        href="https://jigsaw.finance/"
                                        key={item.name}
                                        onClick={() => {
                                            handleScrollToSection(item.target);
                                            setMobileMenuOpen(false);
                                        }}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-poppins font-normal leading-7 text-white hover:bg-black/70"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <Button label="Connect Wallet"/>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}
