import Hero from "./pages/Hero";
import UnlockThePower from "./pages/UnlockThePower";
import UnmatchedProtocol from "./pages/UnmatchedProtocol";
import Solution from "./pages/Solution";
import Protocols from "./pages/Protocols";
import Assets from "./pages/Assets";
import Banner from "./pages/Banner";
import Header from "./components/layout/Header/Header";
import Footer from "./components/layout/Footer/Footer";

function App() {
  return (
    <div>
        <Header/>
        <Hero/>
        <UnlockThePower/>
        <UnmatchedProtocol/>
        <Solution/>
        {/*<Protocols/>*/}
        <Assets/>
        <Banner/>
        <Footer/>
    </div>
  );
}

export default App;
