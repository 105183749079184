export function EZETHIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#67696E"/>
            <path d="M20.0013 8.75L13.095 20.2086L20.0013 17.0701V8.75Z" fill="#DAF4A4"/>
            <path d="M20.0013 17.0701L13.095 20.2085L20.0013 24.2912V17.0701Z" fill="#EEFAD5"/>
            <path d="M26.9076 20.2086L20.0012 8.75V17.0701L26.9076 20.2086Z" fill="#BCDA7E"/>
            <path d="M20.0012 24.2912L26.9076 20.2085L20.0012 17.0701V24.2912Z" fill="#C8E982"/>
            <path d="M13.095 21.5187L20.0013 31.25V25.5988L13.095 21.5187Z" fill="#DAF4A4"/>
            <path d="M20.0012 25.5988V31.25L26.9114 21.5187L20.0012 25.5988Z" fill="#A6BB77"/>
        </svg>


    )
}
