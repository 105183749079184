import PrimaryButton from "../components/base/Button";
import jigsawTile from "../media/images/jigsaw-b.png"
export function Banner() {
    return (
        <section id="unmatched-protocol" aria-label="Unmatched Protocol">
            <div className="wrapper max-sm:px-2">
                <div
                    className="flex max-w-7xl w-full flex-col justify-center items-start gap-2.5 p-2.5 pr-28 max-lg:pr-20 max-md:p-2.5">
                    <div
                        className="flex w-full justify-start items-center flex-wrap relative max-sm:flex-wrap max-sm:flex-col max-sm:space-x-0 max-sm:gap-8">
                        <img src={jigsawTile} alt='jigsaw blockchain lending protocol logo' className="w-1/2 border-none max-sm:w-full block" />

                        <div
                            className="flex flex-col absolute max-sm:relative right-0 justify-start items-start max-sm:items-center flex-grow-0 flex-shrink-0 gap-6 max-w-[470px]">
                            <h1 className="flex-grow-0 flex-shrink-0 text-4xl leading-snug font-semibold font-poppins max-sm:text-center text-white max-sm:text-3xl">
                                Experience the seamless liquidity of Jigsaw
                            </h1>
                            <p className="max-w-lg max-sm:text-center">
                                Deposit collateral and unlock a multitude of opportunities.
                            </p>
                            <div className="mt-6 flex justify-center gap-x-4">
                                <a href='https://app.jigsaw.finance' target="_blank" rel='noreferrer'>
                                    <PrimaryButton className='px-10' label="Get Started"> </PrimaryButton>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
