export function WSTETHIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#00A3FF"/>
            <g clipPath="url(#clip0_110_24475)">
                <path
                    d="M12.7 18.7L12.5 19C10.2 22.6 10.7 27.3 13.8 30.3C15.5 32.1 17.9 33 20.3 33C20.3 33 20.3 33 12.7 18.7Z"
                    fill="white"/>
                <path opacity="0.6" d="M20.3 23L12.7 18.6C20.3 33 20.3 33 20.3 33C20.3 29.9 20.3 26.3 20.3 23Z"
                      fill="white"/>
                <path opacity="0.6"
                      d="M27.9 18.7L28.1 19C30.4 22.6 29.9 27.3 26.8 30.3C25 32.1 22.7 33 20.3 33C20.3 33 20.3 33 27.9 18.7Z"
                      fill="white"/>
                <path opacity="0.2" d="M20.3 23L27.9 18.6C20.3 33 20.3 33 20.3 33C20.3 29.9 20.3 26.3 20.3 23Z"
                      fill="white"/>
                <path opacity="0.2" d="M20.3 13.3V20.8L26.9 17.1L20.3 13.3Z" fill="white"/>
                <path opacity="0.6" d="M20.3 13.3L13.7 17.1L20.3 20.8V13.3Z" fill="white"/>
                <path d="M20.3 7L13.7 17.1L20.3 13.3V7Z" fill="white"/>
                <path opacity="0.6" d="M20.3 13.3L26.9 17.1L20.3 7V13.3Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_110_24475">
                    <rect width="18.6" height="26" fill="white" transform="translate(11 7)"/>
                </clipPath>
            </defs>
        </svg>

    )
}
