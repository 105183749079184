export function USDCIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_618_6389)">
                <path d="M20 40C31.0834 40 40 31.0834 40 20C40 8.9166 31.0834 0 20 0C8.9166 0 0 8.9166 0 20C0 31.0834 8.9166 40 20 40Z" fill="#2775CA"/>
                <path d="M25.5025 23.166C25.5025 20.2494 23.7525 19.2494 20.2525 18.8328C17.7525 18.4994 17.2525 17.8328 17.2525 16.666C17.2525 15.4992 18.0859 14.7494 19.7525 14.7494C21.2525 14.7494 22.0859 15.2494 22.5025 16.4994C22.5859 16.7494 22.8359 16.916 23.0859 16.916H24.4191C24.7525 16.916 25.0025 16.666 25.0025 16.3328V16.2494C24.6691 14.416 23.1691 12.9994 21.2525 12.8328V10.8328C21.2525 10.4994 21.0025 10.2494 20.5859 10.166H19.3359C19.0025 10.166 18.7525 10.416 18.6691 10.8328V12.7494C16.1691 13.0828 14.5859 14.7494 14.5859 16.8328C14.5859 19.5828 16.2525 20.666 19.7525 21.0828C22.0859 21.4994 22.8359 21.9994 22.8359 23.3328C22.8359 24.6662 21.6691 25.5829 20.0859 25.5829C17.9191 25.5829 17.1691 24.666 16.9191 23.416C16.8359 23.0828 16.5859 22.916 16.3359 22.916H14.9191C14.5859 22.916 14.3359 23.166 14.3359 23.4994V23.5828C14.6691 25.666 16.0025 27.1661 18.7525 27.5829V29.5829C18.7525 29.9161 19.0025 30.1661 19.4191 30.2495H20.6691C21.0025 30.2495 21.2525 29.9995 21.3359 29.5829V27.5829C23.8359 27.1661 25.5025 25.416 25.5025 23.166Z" fill="white"/>
                <path d="M15.7516 31.9172C9.25162 29.584 5.91823 22.334 8.33502 15.9172C9.58502 12.4172 12.335 9.75058 15.7516 8.50058C16.085 8.33398 16.2516 8.08398 16.2516 7.66718V6.50058C16.2516 6.16718 16.085 5.91718 15.7516 5.83398C15.6682 5.83398 15.5016 5.83398 15.4182 5.91718C7.50163 8.41718 3.16822 16.834 5.66822 24.7506C7.16822 29.4172 10.7516 33.0006 15.4182 34.5006C15.7516 34.6672 16.085 34.5006 16.1682 34.1672C16.2516 34.084 16.2516 34.0006 16.2516 33.834V32.6672C16.2516 32.4172 16.0016 32.084 15.7516 31.9172ZM24.585 5.91718C24.2516 5.75058 23.9182 5.91718 23.835 6.25058C23.7516 6.33398 23.7516 6.41718 23.7516 6.58398V7.75058C23.7516 8.08398 24.0016 8.41718 24.2516 8.58398C30.7516 10.9172 34.085 18.1672 31.6682 24.584C30.4182 28.084 27.6682 30.7506 24.2516 32.0006C23.9182 32.1672 23.7516 32.4172 23.7516 32.834V34.0006C23.7516 34.334 23.9182 34.584 24.2516 34.6672C24.335 34.6672 24.5016 34.6672 24.585 34.584C32.5016 32.084 36.835 23.6672 34.335 15.7506C32.835 11.0006 29.1682 7.41718 24.585 5.91718Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_618_6389">
                    <rect width="40" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
