export function BTCIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_109_24430)">
                <path
                    d="M39.3971 24.8381C36.726 35.5525 25.874 42.0731 15.1584 39.4012C4.44722 36.7301 -2.07335 25.8775 0.59897 15.1639C3.26894 4.44838 14.1209 -2.07268 24.8332 0.598468C35.5481 3.26961 42.0683 14.1234 39.3968 24.8383L39.397 24.8381H39.3971Z"
                    fill="#F7931A"/>
                <path
                    d="M28.8168 17.1504C29.2148 14.4889 27.1885 13.0582 24.4177 12.1038L25.3165 8.49845L23.1219 7.95159L22.2468 11.462C21.6699 11.3181 21.0774 11.1825 20.4885 11.0481L21.3698 7.51451L19.1765 6.96765L18.2772 10.5718C17.7997 10.4631 17.3308 10.3557 16.8758 10.2425L16.8783 10.2311L13.8519 9.47534L13.268 11.8194C13.268 11.8194 14.8963 12.1926 14.862 12.2156C15.7507 12.4374 15.9114 13.0257 15.8847 13.492L14.8608 17.5993C14.922 17.6149 15.0014 17.6374 15.089 17.6726C15.0158 17.6544 14.9379 17.6345 14.857 17.6152L13.4218 23.3689C13.3132 23.6389 13.0375 24.0441 12.4162 23.8903C12.4382 23.9221 10.8211 23.4922 10.8211 23.4922L9.73145 26.0044L12.5875 26.7164C13.1188 26.8496 13.6394 26.989 14.1522 27.1201L13.244 30.7668L15.4361 31.3136L16.3355 27.7057C16.9344 27.8683 17.5155 28.0182 18.0845 28.1596L17.1881 31.7505L19.3829 32.2974L20.291 28.6575C24.0334 29.3658 26.8473 29.0803 28.0318 25.6953C28.9862 22.97 27.9843 21.398 26.0154 20.3729C27.4494 20.0422 28.5296 19.099 28.8176 17.1507L28.8169 17.1502L28.8168 17.1504ZM23.8025 24.1818C23.1242 26.9071 18.5356 25.4339 17.048 25.0644L18.2531 20.2332C19.7407 20.6046 24.5113 21.3396 23.8026 24.1818H23.8025ZM24.4812 17.1109C23.8625 19.5899 20.0434 18.3305 18.8045 18.0217L19.8971 13.64C21.136 13.9489 25.1257 14.5252 24.4814 17.1109H24.4812Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_109_24430">
                    <rect width="39.9955" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    )
}
