import video from '../media/video/JigVid.mp4'
import {useEffect, useRef, useState} from "react";

export function Solution() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const videoRef = useRef(null);
    const [videoHeight, setVideoHeight] = useState(0);
    const [isMobileViewport, setIsMobileViewport] = useState(true);


    const handleResize = () => {
        setIsMobileViewport(window.innerWidth < 1024); // lg
        if (videoRef.current) {
            setVideoHeight(videoRef.current.clientHeight);
        }
    };

    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            setVideoHeight(videoRef.current.clientHeight);
        }
    };

    useEffect(() => {
        const videoComponent = videoRef.current
        if (videoComponent) {
            videoComponent.addEventListener('loadedmetadata', handleLoadedMetadata);
            setVideoHeight(videoRef.current.clientHeight);
        }

        return () => {
            videoComponent.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    useEffect(() => {
        setIsMobileViewport(window.innerWidth < 1024); // lg
        if (videoRef.current) {
            setVideoHeight(videoRef.current.clientHeight);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const refs = [ref1, ref2, ref3, ref4]
        const stickyTop = (window.innerHeight - videoHeight) / 2;
        const onScroll = () => {
            if (!isMobileViewport) {
                refs.forEach((ref, index) => {
                    let opacity = 0;
                    const elementTitle = document.getElementById(`id-title`)
                    const elementHiddenTitle = document.getElementById(`id-hidden-title`)

                    if (ref.current) {
                        const rect = ref.current.getBoundingClientRect();
                        const element = document.getElementById(`id-${index + 1}`)

                        if (index < refs.length - 1) {
                            const rectNext = refs[index + 1].current?.getBoundingClientRect();
                            if (Math.round(rect.top) > Math.round(stickyTop)) {
                                opacity = (index === 0) ? 1 : (rect.top - (window.innerHeight) / 2) / (stickyTop - (window.innerHeight) / 2)
                            } else {
                                opacity = 1 - (rectNext.top - window.innerHeight / 2) / (stickyTop - window.innerHeight / 2)
                            }
                        } else {
                            if (Math.round(rect.top) > Math.round(stickyTop)) {
                                elementTitle.style.setProperty('position', `sticky`);
                                elementHiddenTitle.style.setProperty('display', `none`);
                                elementTitle.style.setProperty('top', `calc(50vh - ${videoHeight / 2}px - 128px)`);
                                elementTitle.style.setProperty('bottom', `auto`);
                                opacity = (rect.top - (window.innerHeight) / 2) / (stickyTop - (window.innerHeight) / 2) // linear function for changing opacity in [0,1] interval

                            } else {
                                elementTitle.style.setProperty('position', `absolute`);
                                elementHiddenTitle.style.setProperty('display', `block`);
                                elementTitle.style.setProperty('bottom', `${videoHeight + 2 * 96}px`);
                                elementTitle.style.setProperty('top', `auto`);
                                opacity = 1
                            }
                        }
                        if (opacity > 1) {
                            opacity = 1
                        }
                        if (opacity < 0) {
                            opacity = 0
                        }
                        element.style.setProperty('opacity', opacity.toString());
                    }
                })
            }

        };
        window.addEventListener('scroll', onScroll);

        if (!isMobileViewport) {
            [1, 2, 3, 4].forEach((value) => {
                const element = document.getElementById(`id-${value}`)
                element.style.setProperty('top', `calc(50vh - ${videoHeight / 2}px)`);
                element.style.setProperty('height', `${videoHeight}px`);
            })
            const element = document.getElementById(`id-5`)
            const elementTitle = document.getElementById(`id-title`)
            element.style.setProperty('top', `calc(50vh - ${videoHeight / 2}px)`);
            elementTitle.style.setProperty('top', `calc(50vh - ${videoHeight / 2}px - 128px)`);
        }

        // Cleanup on unmount
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [videoHeight, isMobileViewport]);

    return (
        <section id="solution" aria-label="Solution" className='relative'>
            <div className="wrapper px-6">
                <h2 id='id-title' className="sticky top-32 max-lg:relative max-lg:top-0">Jigsaw solution</h2>
                <h2 id='id-hidden-title' className="hidden opacity-0 top-32 max-lg:static ">Jigsaw solution</h2>

                <div
                    className="flex flex-row flex-wrap justify-center items-start relative gap-32 max-lg:gap-24 max-lg:flex-col max-lg:items-center">
                    <div
                        className='relative flex scroll-smooth flex-col justify-start items-start gap-96 max-sm:gap-12 max-lg:gap-12 max-lg:item-center'>
                        <div ref={ref1}
                             id='id-1'
                             className={`sticky flex flex-col opacity-1 justify-center items-start gap-2 max-md:h-full max-lg:relative`}
                        >
                            <h3>Empowering</h3>
                            <p className="w-64">
                                Start by simply making a deposit into your Jigsaw box to unlock the unlimited potential of dynamic collateral
                            </p>
                        </div>
                        <div ref={ref2}
                             id='id-2'
                             className='sticky flex flex-col justify-center items-start gap-2 opacity-0 max-md:h-full max-lg:relative max-lg:opacity-100'>
                            <h3>Convenient</h3>
                            <p className="w-64">
                                Borrow jUSD against your collateral with a 0% borrow fee
                            </p>
                        </div>
                        <div ref={ref3}
                             id='id-3'
                             className='sticky flex flex-col justify-center items-start gap-2 opacity-0 max-md:h-full max-lg:relative max-lg:opacity-100'>
                            <h3>Seamless</h3>
                            <p className="w-64">Enables swapping and/or investing into supported protocols
                                efficiently
                            </p>
                        </div>
                        <div ref={ref4}
                             id='id-4'
                             className='sticky flex flex-col justify-center items-start gap-2 opacity-0 max-md:h-full max-lg:relative max-lg:opacity-100'>
                            <h3>Flexible</h3>
                            <p className="w-64">You don't lose any flexibility as long as your collateral
                                requirements are satisfied
                            </p>
                        </div>
                    </div>
                    <div
                        id='id-5'
                        className='sticky flex flex-col justify-center w-1/2  max-md:relative max-lg:w-full max-md:h-full  max-lg:relative'>
                        <video
                            id="solutionVideo"
                            ref={videoRef}
                            src={video}
                            autoPlay
                            muted
                            loop
                            playsInline
                            preload="none"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Solution
