import {DepositIcon} from "../components/icons/base/Deposit";
import {BorrowIcon} from "../components/icons/base/Borrow";
import {SwapIcon} from "../components/icons/base/Swap";
import {InvestIcon} from "../components/icons/base/Invest";

export function UnlockThePower() {
  return (
    <section id="unlock-the-power" aria-label="Unlock the power of Pandora box" className='relative z-20 bg-black '>
      <div className="wrapper max-sm:px-5">
        <h2>Unlock the Power of Jigsaw</h2>
        <div className="flex justify-center items-start flex-wrap max-w-[920px] gap-32 max-sm:gap-20 max-sm:w-full">
          <div className="flex flex-col justify-start items-start relative gap-4">
            <DepositIcon/>
            <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-6">
              <h3>Deposit</h3>
              <p className="w-[390px] leading-relaxed max-sm:w-full">
                Deposit assets into your Jigsaw box, opening up endless opportunities for leverage
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start relative gap-4">
           <BorrowIcon/>
            <div className="flex flex-col justify-start items-start relative gap-6">
              <h3>Borrow</h3>
              <p className="w-[390px] leading-relaxed max-sm:w-full">
                Borrow jUSD against your collateral with a 0-5% fee and freely withdraw it
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start relative gap-4">
           <SwapIcon/>
            <div className="flex flex-col justify-start items-start relative gap-6">
              <h3>Swap</h3>
              <p className="w-[390px] leading-relaxed max-sm:w-full">
                Swap your collateral for any other supported asset with just a click
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start relative gap-4">
            <InvestIcon/>
            <div className="flex flex-col justify-start items-start relative gap-6">
              <h3>Invest</h3>
              <p className="w-[390px] leading-relaxed max-sm:w-full">
                Venture into supported yield farms effortlessly, claiming rewards and bridging cross-chain
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UnlockThePower
