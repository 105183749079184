import {DaiIcon} from "../../icons/assets/DAI";
import {USDCIcon} from "../../icons/assets/USDC";
import {TetherIcon} from "../../icons/assets/Tether";
import {ETHIcon} from "../../icons/assets/ETH";
import {BTCIcon} from "../../icons/assets/BTC";
import {REETHIcon} from "../../icons/assets/REETH";
import {WSTETHIcon} from "../../icons/assets/wstETH";
import {RSETHIcon} from "../../icons/assets/RSETH";
import {RSWETHIcon} from "../../icons/assets/RSWETH";
import {EZETHIcon} from "../../icons/assets/EZETH";


export const assetList = [
    {
        title: "BTC",
        icon: <BTCIcon/>,
    },
    {
        title: "USDC",
        icon: <USDCIcon/>,
    },
    {
        title: "USDT",
        icon: <TetherIcon/>,
    },
    {
        title: "ETH",
        icon: <ETHIcon/>,
    },
    {
        title: "reETH",
        icon: <REETHIcon/>,
    },
    {
        title: "wstETH",
        icon: <WSTETHIcon/>,
    },
    {
        title: "rsETH",
        icon: <RSETHIcon/>,
    },
    {
        title: "rswETH",
        icon: <RSWETHIcon/>,
    },
    {
        title: "ezETH",
        icon: <EZETHIcon/>,
    },
];
