export function TwitterIcon(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_104_17831)">
                <path
                    d="M13.6 16.25H16.054L10.694 10.108L17 1.75H12.063L8.196 6.82L3.771 1.75H1.316L7.049 8.32L1 16.25H6.063L9.558 11.617L13.6 16.25ZM12.74 3.222H14.1L5.323 14.855H3.865L12.74 3.222Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_104_17831">
                    <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 1 17)"/>
                </clipPath>
            </defs>
        </svg>

    )
}
